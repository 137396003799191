/* eslint-disable @typescript-eslint/no-unused-vars */
import { CATEGORIES, BRANDS, TAGS } from './catalogs'

export const PRODUCTS = [
    {
        id: '000001',
        title: 'Prestige Platina Popular Stainless Steel Gas and Induction Compatible Casserole with Glass Lid',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.POTS_PANS, CATEGORIES.INDUCTION_COOKWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09F3DRD1M',
        image: '000001.png',
    },
    {
        id: '000002',
        title: 'Non Stick Deep Frying Pan, Granite Kadai with Lid, Induction Wok, Saute Pan, Biriyani Pot',
        brand: BRANDS.Carote,
        categories: [CATEGORIES.POTS_PANS, CATEGORIES.INDUCTION_COOKWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09V7LC2DY',
        image: '000002.png',
    },
    {
        id: '000003',
        title: 'Bergner Argent Triply Stainless Steel Sautepan/ Deep Frypan with Stainless Steel Lid, 26 cm, 3.1 Litres, Induction Base, Silver, Gas Ready',
        brand: BRANDS.Bergner,
        categories: [CATEGORIES.POTS_PANS, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.frypan, TAGS.frying],
        targetLink: 'https://www.amazon.in/dp/B00LP7FV9W',
        image: '000003.png',
    },
    {
        id: '000004',
        title: 'Hawkins Stainless Steel Tpan 1.5L with Glass lid',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.POTS_PANS],
        tags: [TAGS.tpan, TAGS.glasslid],
        targetLink: 'https://www.amazon.in/dp/B079M2PDLL',
        image: '000004.png',
    },
    {
        id: '000005',
        title: 'Hawkins 1 Litre Tpan, Stainless Steel Tea Pan, Induction Sauce Pan, Chai Pan, Small Pan, Silver',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.POTS_PANS, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.tpan],
        targetLink: 'https://www.amazon.in/dp/B07TCQ2RG7',
        image: '000005.png',
    },
    {
        id: '000006',
        title: 'Futura 3 Litre Cook n Serve Stewpot, Non Stick Pot with Glass Lid, Cooking Pot with Lid, Black',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.POTS_PANS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00JFUD8J6',
        image: '000006.png',
    },
    {
        id: '000007',
        title: 'Hawkins Futura 3 Litre Cook n Serve Bowl, Hard Anodised Saucepan with Hard Anodised Lid, Induction Pan, Sauce Pan for Cooking and Serving, Black (IACB30) (Aluminium)',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.POTS_PANS, CATEGORIES.INDUCTION_COOKWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00JFUCAH2',
        image: '000007.png',
    },
    {
        id: '000008',
        title: 'Futura 3.25 Litre Curry Pan, Non Stick Saute Pan with Glass Lid, Deep Pan for Frying, Black',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.POTS_PANS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00EVQYLOU',
        image: '000008.png',
    },
    {
        id: '000009',
        title: 'Borosil Stainless Steel Handi Casserole with Lid, Impact Bonded Tri-Ply Bottom, 2.2 L, Silver',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.POTS_PANS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07RDJ6ZGD',
        image: '000009.png',
    },
    {
        id: '0000010',
        title: 'Amazon Brand - Solimo Stainless Steel T Pan with Glass Lid & Induction Base, 1.5 Litre',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.POTS_PANS, CATEGORIES.INDUCTION_COOKWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07P7X94NP',
        image: '0000010.png',
    },
    {
        id: '0000011',
        title: 'Prestige Platina Popular Stainless Steel Gas and Induction Compatible Fry Pan',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.POTS_PANS, CATEGORIES.INDUCTION_COOKWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09F2KTLPX',
        image: '0000011.png',
    },
    {
        id: '0000012',
        title: 'Amazon Brand - Solimo Stainless Steel Saucepan with Glass Lid, Induction Base',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.POTS_PANS, CATEGORIES.INDUCTION_COOKWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07P5TXSWJ',
        image: '0000012.png',
    },
    {
        id: '0000013',
        title: 'Hawkins Futura 26 cm Frying Pan, Non Stick Fry Pan with Glass Lid, Frypan, Black',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.POTS_PANS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00EVQYIQQ',
        image: '0000013.png',
    },
    {
        id: '0000014',
        title: 'Cook n Serve Handi, Triply Stainless Steel Handi with Glass Lid, Induction Sauce Pan, Biryani Handi, Saucepan, Silver',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.POTS_PANS, CATEGORIES.INDUCTION_COOKWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08FS18MPK',
        image: '0000014.png',
    },
    {
        id: '0000015',
        title: 'Amazon Brand - Solimo Stainless Steel Sautee Pan with Lid, 24 cm, Silver',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.POTS_PANS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07MWGLWF3',
        image: '0000015.png',
    },
    {
        id: '0000016',
        title: 'Tri-Ply Stainless Steel Tope with Steel Lid',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.POTS_PANS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07P923CC7',
        image: '0000016.png',
    },
    {
        id: '0000017',
        title: 'Hard Anodized Saucepan with Bakelite Handle - 1.5 litres (28cm, 3mm thickness), Aluminium',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.POTS_PANS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07P5SX4F1',
        image: '0000017.png',
    },
    {
        id: '0000018',
        title: 'Futura Hard Anodised Cook-n-Serve Bowl 5 L, 23 cm, 4.06 mm, Aluminium, Black',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.POTS_PANS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0096DS2QQ',
        image: '0000018.png',
    },
    {
        id: '0000019',
        title: 'SignoraWare 3500ml Artista Tri-Ply Non-Stick Deep Fry Kadhai 24cm, Induction Compatible Cookware Kadai, Grade 304 Stainless Steel, Food Grade, Dishwasher Safe',
        brand: BRANDS.Signoraware,
        categories: [CATEGORIES.TAWA_KADHAI, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.kadai],
        targetLink: 'https://www.amazon.in/dp/B08XW23584',
        image: '0000019.png',
    },
    {
        id: '0000020',
        title: 'Stainless Steel Induction Bottom Kadhai Set size 22 cm, 27 cm (2 pieces, 2 Liters, and 3.6 Liters)',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.TAWA_KADHAI, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.kadai],
        targetLink: 'https://www.amazon.in/dp/B07PB6BFHX',
        image: '0000020.png',
    },
    {
        id: '0000021',
        title: 'Dosa Tawa Non Stick Pan, Granite Tawa for Roti, Flat Dosa Tava, Induction Roti Tawa, 24cm, White',
        brand: BRANDS.Carote,
        categories: [CATEGORIES.TAWA_KADHAI, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.tawa, TAGS.tava],
        targetLink: 'https://www.amazon.in/dp/B09M6DZY34',
        image: '0000021.png',
    },
    {
        id: '0000023',
        title: 'The Indus Valley Cast Iron Tawa/Tava for Dosa/Roti/Chappati/Naan with Long Handle | 10 Inch, 1.9 kg, Gas & Induction-Friendly | Pre-Seasoned, 100% Toxin-Free, Naturally Non-Stick, Long Lasting',
        brand: BRANDS.The_Indus_Valley,
        categories: [CATEGORIES.TAWA_KADHAI, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.tawa, TAGS.tava],
        targetLink: 'https://www.amazon.in/dp/B07JVXC599',
        image: '0000023.png',
    },
    {
        id: '0000024',
        title: 'Hawkins Futura Nonstick Dosa Tava, Diameter 33 cm, Thickness 4.88 mm (Black) ',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.TAWA_KADHAI],
        tags: [TAGS.tawa, TAGS.tava],
        targetLink: 'https://www.amazon.in/dp/B0091X7RVM',
        image: '0000024.png',
    },
    {
        id: '0000025',
        title: 'Rock Tawa Dosa Tawa 12 Inch Pre-Seasoned Cast Iron Skillet, Black',
        brand: BRANDS.Rock_Tawa,
        categories: [CATEGORIES.TAWA_KADHAI],
        tags: [TAGS.tava],
        targetLink: 'https://www.amazon.in/dp/B01M0YZBMM',
        image: '0000025.png',
    },
    {
        id: '0000026',
        title: 'NIRLON Stainless Steel Induction & Gas Compatible Sandwich Bottom Casserole with Glass Lid/Belly Cass, Silver',
        brand: BRANDS.Nirlon,
        categories: [CATEGORIES.POTS_PANS, CATEGORIES.INDUCTION_COOKWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0BTMHKLZL',
        image: '0000026.png',
    },
    {
        id: '0000027',
        title: 'Solimo Tri-ply Stainless Steel Induction Base Kadhai with Steel lid',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.TAWA_KADHAI, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.kadai],
        targetLink: 'https://www.amazon.in/dp/B08BM1M3RJ',
        image: '0000027.png',
    },
    {
        id: '0000028',
        title: 'HEMER Juwel Triply Stainless Steel Kadai with Lid - 26 cm – 3.5 litres - Tri ply Kadhai with Customized Dome Shaped Lid - SS Riveted Cast Handle - Induction and Gas Stove Friendly',
        brand: BRANDS.Hemer,
        categories: [CATEGORIES.TAWA_KADHAI, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.kadai],
        targetLink: 'https://www.amazon.in/dp/B09VPNJQWJ',
        image: '0000028.png',
    },
    {
        id: '0000029',
        title: 'Milton Pro Cook Triply Stainless Steel Kadhai with Lid',
        brand: BRANDS.Milton,
        categories: [CATEGORIES.TAWA_KADHAI],
        tags: [TAGS.kadai],
        targetLink: 'https://www.amazon.in/dp/B08K4B958Y',
        image: '0000029.png',
    },
    {
        id: '0000030',
        title: 'Borosil - Stainless Steel Deep Kadhai with Lid, Impact Bonded Tri-Ply Bottom',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.TAWA_KADHAI],
        tags: [TAGS.kadai],
        targetLink: 'https://www.amazon.in/dp/B07RKV6S22',
        image: '0000030.png',
    },
    {
        id: '0000031',
        title: 'Stahl Triply Stainless Steel Kadai with Lid | Stainless Steel Cookware Tri Ply Kadhai | Gas & Induction Base | Artisan 4326, Dia 26 cm, 3.3 L',
        brand: BRANDS.Stahl,
        categories: [CATEGORIES.TAWA_KADHAI, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.kadai],
        targetLink: 'https://www.amazon.in/dp/B011VH3RQE',
        image: '0000031.png',
    },
    {
        id: '0000032',
        title: 'Bergner Argent Tri-Ply Stainless Steel Kadhai with Stainless Steel lid (24 cm, 2.5 Liters, Induction Base, Silver)',
        brand: BRANDS.Bergner,
        categories: [CATEGORIES.TAWA_KADHAI, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.kadai],
        targetLink: 'https://www.amazon.in/dp/B00E5D9HAM',
        image: '0000032.png',
    },
    {
        id: '0000033',
        title: 'Hawkins Futura Nonstick Kadhai 2.5 L, 26 cm, 3.25 mm with SS lid (Black)',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.TAWA_KADHAI],
        tags: [TAGS.kadai],
        targetLink: 'https://www.amazon.in/dp/B003SLDNNO',
        image: '0000033.png',
    },
    {
        id: '0000034',
        title: 'Vinod Platinum Triply Stainless Steel Kadai with Stainless Steel Lid 2.5 litres Capacity (24 cm Diameter) | 24 cm Stainless Steel Kadhai with Lid - Silver (Induction and Gas Stove Friendly)',
        brand: BRANDS.Vinod,
        categories: [CATEGORIES.TAWA_KADHAI, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.kadai],
        targetLink: 'https://www.amazon.in/dp/B01NHBV5JY',
        image: '0000034.png',
    },
    {
        id: '0000036',
        title: 'Milton Pro Cook Black Pearl Induction Kadhai, 26 cm / 3.4 Litre (Aluminium)',
        brand: BRANDS.Milton,
        categories: [CATEGORIES.TAWA_KADHAI, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.kadai],
        targetLink: 'https://www.amazon.in/dp/B08KM88Z9H',
        image: '0000036.png',
    },
    {
        id: '0000037',
        title: 'Non Stick Kadai with Lid, Induction Kadai, Granite Covered Kadhai, Biriyani Pot, Kadhai for Deep Frying, 24cm, White',
        brand: BRANDS.Carote,
        categories: [CATEGORIES.TAWA_KADHAI, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.kadai],
        targetLink: 'https://www.amazon.in/dp/B09V7LBNDR',
        image: '0000037.png',
    },
    {
        id: '0000038',
        title: 'Soup Spoon/Soup Spoons Ceramic Soup Spoon/Soup Spoon Set of 6 Pure Ceramic White Color Microwave Safe 15 cm Soup Spoons',
        brand: BRANDS.Vemlons,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0991MG6L9',
        image: '0000038.png',
    },
    {
        id: '0000046',
        title: 'Silicone Spatula Set for Kitchen, Spatula Silicone Set for Non Stick Pan, Kitchen Tools Set of 3 (32cm Turner+32cm Turner+32cm Spoon)',
        brand: BRANDS.Carote,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09VKFMJXD',
        image: '0000046.png',
    },
    {
        id: '0000047',
        title: 'Premium Silicone Non-Stick Heat Resistant Spatulas with Steel Core Kitchen Utensils Non-Stick for Cooking, Baking and Mixing, 27cm,Red',
        brand: BRANDS.Clazkit,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07NDR5MNB',
        image: '0000047.png',
    },
    {
        id: '0000048',
        title: 'Silicone Spatulas Heat Resistant Flexible Spatula 450F with Stainless Steel Core - FDA Grade Premium Kitchen Utensils with Good Grip 27 cm Random Color (Pack of 2)',
        brand: BRANDS.Baskety,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07TBLFHPF',
        image: '0000048.png',
    },
    {
        id: '0000049',
        title: 'Silicone Brush and Spatula Set, 2-Pieces, Red',
        brand: BRANDS.Clazkit,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07NDQYT9W',
        image: '0000049.png',
    },
    {
        id: '0000051',
        title: 'Slotted Spatula Turner, Best Turner Spatulas Wood Grip, Pancake Turner Egg Flipper',
        brand: BRANDS.Pepplo,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B082VGFSY8',
        image: '0000051.png',
    },
    {
        id: '0000052',
        title: 'Kitchen Stainless Steel Best Skimmer Slotted Spoon-Cooking Utensils with Heat Resistant Wooden Handle and Stay-Cool(Set of 1pc)',
        brand: BRANDS.Pepplo,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B082VZSQYB',
        image: '0000052.png',
    },
    {
        id: '0000053',
        title: 'Stainless Steel Spatula Vegetable Shovel Wooden Handle, Stainless Steel Pearl Turner Perforated Server',
        brand: BRANDS.Pepplo,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B082VFLMFL',
        image: '0000053.png',
    },
    {
        id: '0000054',
        title: 'Stainless Steel Soup Ladle with Wooden Grip, Large Kitchen Utensil Spoon, Punch Bowl and Soup Pan Ladle',
        brand: BRANDS.Pepplo,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B082V7ST7J',
        image: '0000054.png',
    },
    {
        id: '0000055',
        title: 'Stainless Steel Turner/Spatula for Dosa, Roti, Omlette, Parathas, PavBhaji (Silver), Standard',
        brand: BRANDS.Kuber_Industries,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09PGBR6SC',
        image: '0000055.png',
    },
    {
        id: '0000057',
        title: 'Sheesham Wooden Spatula, Ladle and Spoon | for Cooking in Non Stick Pan |100% Natural Wooden ladles and Wooden Spoons | Heat Resistant & Durable',
        brand: BRANDS.The_Better_Home,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0BB776BQP',
        image: '0000057.png',
    },
    {
        id: '0000059',
        title: 'IKEA Modish Rubber Spatula (Beige/Blue)',
        brand: BRANDS.Ikea,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09MHQN6KC',
        image: '0000059.png',
    },
    {
        id: '0000060',
        title: 'IKEA Silicone Rubber Bristles Pastry Brush',
        brand: BRANDS.Ikea,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09QCZY5NZ',
        image: '0000060.png',
    },
    {
        id: '0000061',
        title: 'Wok Spatula, [Rustproof, Durable] 304 Stainless Steel Spatula Turner Spatula with Heat Resistant Wooden Handle, Cooking Shovel Ladle for Eggs, Pancakes, Omelet, Burger and Other Foods',
        brand: BRANDS.Leeonz,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08RX82CCW',
        image: '0000061.png',
    },
    {
        id: '0000062',
        title: 'Stainless Steel Slotted Turner Spatula, Professional Food Turner Flipper with Heat Resistant Wooden Handle, Kitchen Utensil Cooking Pancake Turner for Eggs, Omelets, Burgers, 14.1 Inches',
        brand: BRANDS.Leeonz,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08RX96S8X',
        image: '0000062.png',
    },
    {
        id: '0000063',
        title: 'The Indus Valley Neem Wooden Spatula for Cooking/Sauteing/Stirring/Mixing/Serving Food and Gravy, 26.5 cm, 40 Gm, Neem Wood, Brown',
        brand: BRANDS.The_Indus_Valley,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B01LF3MIY6',
        image: '0000063.png',
    },
    {
        id: '0000064',
        title: 'Woodtula Silicone Slotted Spatula Turner,Heat Resistant up to 445°F, BPA Free, Food Grade Wooden Handle Nonstick Flipper for Fish, Eggs, Omelets 32 cm',
        brand: BRANDS.Spatlus,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08YRWWTJV',
        image: '0000064.png',
    },
    {
        id: '0000065',
        title: 'Silicone Serving Spoon with Heat Resistant Silicone Covering Head and Stay-Cool Wooden Handle (Grey)',
        brand: BRANDS.Clazkit,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08LDT9JF2',
        image: '0000065.png',
    },
    {
        id: '0000066',
        title: 'Woodtula Silicone Spatula, BPA Free Heat Resistant up to 445°F,Wooden Handle Non Stick Rubber Kitchen Spatulas for Cooking, Baking, and Mixing 31.7 cm Pink',
        brand: BRANDS.Spatlus,
        categories: [CATEGORIES.CUTLERIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08YRVFSLR',
        image: '0000066.png',
    },
    {
        id: '0000067',
        title: 'Incrizma Glassware - Imported Vertical Lines Glass Storage Jar with Lid - 2000 ml',
        brand: BRANDS.Incrizma,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07RC8SKV5',
        image: '0000067.jpg',
    },
    {
        id: '0000068',
        title: 'PrimeWorld European Crystal Clear Stemless 300 ml Glasses Set of 6 pcs - Drinking Glasses for- Water, Juice, Colddrink, Mojito, Cocktail, Lead-Free, Perfect for Home, Restaurants and Parties',
        brand: BRANDS.PrimeWorld,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09DRKN3PP',
        image: '0000068.jpg',
    },
    {
        id: '0000069',
        title: 'PrimeWorld Glassware Water/Juice Glass - 6 Pieces, Transparent, 300 ml',
        brand: BRANDS.PrimeWorld,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07MY9LDBJ',
        image: '0000069.webp',
    },
    {
        id: '0000070',
        title: 'Borosil - Carafe Flame Proof Glass Kettle With Stainer, 1L',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.GLASSWARE, CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00E96ISAA',
        image: '0000070.webp',
    },
    {
        id: '0000071',
        title: 'Treo SkyKey Tableware Serving Crystal Desire Lemon Set Water Jug with 6 Glass (Clear)',
        brand: BRANDS.Treo,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07HD8YLD3',
        image: '0000071.webp',
    },
    {
        id: '0000072',
        title: 'Treo by Milton Desire DLX Lemon Jug, 1470 ml, (Glass)',
        brand: BRANDS.Treo,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07W55XMYW',
        image: '0000072.png',
    },
    {
        id: '0000074',
        title: 'Borosil - Vision Jug, 1.3 litres, Glass',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00E97OQJQ',
        image: '0000074.png',
    },
    {
        id: '0000076',
        title: 'Tea and Coffee Cup Glass Mug, Used for Tea, Coffee, Green Tea, Lemon Tea Cup with Handle Golden',
        brand: BRANDS.Kelvee,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08YWXND4Y',
        image: '0000076.jpg',
    },

    {
        id: '0000077',
        title: 'MILTON Desire Lemon Jug, 1470 ml (Glass)',
        brand: BRANDS.Milton,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07W55SSHV',
        image: '0000077.png',
    },
    {
        id: '0000078',
        title: 'Tableware/Dining Ware Green Apple Duck Pot 1.3L Glass Pitcher with Plastic Lid, Drinking Beverage Jug, Glass Water Jug for Home Use',
        brand: BRANDS.Home_Cart,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B082M6NYV2',
        image: '0000078.jpg',
    },
    {
        id: '0000079',
        title: 'Borosil - Marina Jug with Plastic Handle, 1.3 Litres, Transparent',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B01MG93ST4',
        image: '0000079.png',
    },
    {
        id: '0000080',
        title: 'Cello Crystal Borosilicate Jug, 1pc, 1200ml, Clear',
        brand: BRANDS.Cello,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08KYBHZGL',
        image: '0000080.png',
    },
    {
        id: '0000081',
        title: 'Borosil Marina Glass Jug 800ml Transparent',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07GD4F2JL',
        image: '0000081.jpg',
    },
    {
        id: '0000082',
        title: 'Borosil - Carafe Flame Proof Glass Kettle With Stainer, 1L',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00E96ISAA',
        image: '0000082.jpg',
    },
    {
        id: '0000083',
        title: 'Korona 1.1 LTR/1100 ML Glass hot Cold Water ice Tea, Wine. Milk and Juice Beverage Carafes jug Pitcher with lid',
        brand: BRANDS.Korona,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08P5M4R79',
        image: '0000083.jpg',
    },
    {
        id: '0000084',
        title: 'Golden bird International 1500 ML Glass Storage Jars - Clear Glass Jars with Airtight Lids airtight glass Kitchen Jars for Canning, Fermenting,etc',
        brand: BRANDS.Golden_Bird,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09CNSK97B',
        image: '0000084.png',
    },
    {
        id: '0000085',
        title: 'Treo Bruno Jar 3500ml',
        brand: BRANDS.Treo,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07VFP2NJZ',
        image: '0000085.jpg',
    },
    {
        id: '0000087',
        title: 'Transparent Glass Storage Pop Jar 300 ML Kitchen Food Storage Glass Container With Lid Coffee Sugar Salt Tea Herbs Jam Cookie Masla Biscuit Storage Container',
        brand: BRANDS.RKK,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09LS7VHGK',
        image: '0000087.jpg',
    },
    {
        id: '0000088',
        title: 'Glass Water Glass - Set of 6',
        brand: BRANDS.Ramoji_International,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08ZYPKQ47',
        image: '0000088.jpg',
    },
    {
        id: '0000090',
        title: 'Glass Storage Jars with Buckle Lid - Clear Container',
        brand: BRANDS.Star_Work,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0839FCQFQ',
        image: '0000090.jpg',
    },
    {
        id: '0000091',
        title: 'Glass Jars with Airtight Lids Wide Mouth Storage Canister Jars Kitchen Storage Buckle Lid Canning Jar Dry Food Storage Pasta Spice Beans Flour',
        brand: BRANDS.Vasukie,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08RWBKDFZ',
        image: '0000091.jpg',
    },
    {
        id: '0000092',
        title: 'Airtight Canister Glass Kitchen Storage Jar Container With Clip Lock For storage Round Jar Masala,Honey,Spice,aachar,Sugar,Coffee,cereal,with silicon airtight',
        brand: BRANDS.Satvikaya,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08RWC2JKD',
        image: '0000092.jpg',
    },
    {
        id: '0000093',
        title: 'Treo by Milton Milano Glass Tumbler Set of 6, 340 ml Each, Transparent | Serve Beer | Juice | Drinks | Wine | Water | Cocktails | Whiskey | Mixed Drinks',
        brand: BRANDS.Treo,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0B7MMFFPJ',
        image: '0000093.jpg',
    },
    {
        id: '0000096',
        title: 'Glass 1500 Ml Pumpkin Pop Shape Kitchen Food Storage Jar',
        brand: BRANDS.M_T_Brothers,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08T63K77Q',
        image: '0000096.jpg',
    },

    {
        id: '0000097',
        title: 'Transparent Glass Coffee Mugs 300 ML Irish Latte Cups, Milkshake Falooda & Juice Glass Cappuccino and Hot Chocolate Mugs with Handle, Clear Glass Mugs for Hot Beverages Pieces',
        brand: BRANDS.RKK,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B094DM34LY',
        image: '0000097.jpg',
    },
    {
        id: '0000098',
        title: 'Hilofy 300ML – Set of 6 Milkshake, Falooda Glass, Ice-Cream Glass, Juice Glass, Water Glass, Wine Glass, Beer Glass',
        brand: BRANDS.Hilofy,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09GYJ11W9',
        image: '0000098.jpg',
    },
    {
        id: '0000099',
        title: 'Cloudsell Glass Solid Bowl - 5.3ml, Set of 2, Clear',
        brand: BRANDS.Cloudsell,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B083Y1ZNYS',
        image: '0000099.png',
    },
    {
        id: '00000100',
        title: 'Castanea Glass Water Jug with Air Tight Wooden Lid, Drinking Beverage Pitcher with Cap',
        brand: BRANDS.Castanea,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08WCVDZ74',
        image: '00000100.jpg',
    },
    {
        id: '00000101',
        title: 'Castanea Glass Water Jug, 1 Litre, Heat Resistant Water Jug with Wooden lid, Large Handle, Transparent',
        brand: BRANDS.Castanea,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B092RJB8WM',
        image: '00000101.jpg',
    },
    {
        id: '00000102',
        title: 'Wine Glass Red Wine Party Glass, Whisky Glass, Clear Glasses Crystal Cut Whiskey Glasses Bar Glass for Drinking Bourbon, Whiskey, Scotch, Cocktails, Martini Glass',
        brand: BRANDS.Dropany,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09SM81KW4',
        image: '00000102.jpg',
    },
    {
        id: '00000103',
        title: 'European Barrel Plain 300 ml Clear Water Glasses Set of 6 pcs - Tall Drinking Glasses for- Water, Juice, Cold Drink, Mojito, Cocktail, Lead-Free, Perfect for Home, Restaurants and Parties',
        brand: BRANDS.PrimeWorld,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09DRMY3P4',
        image: '00000103.jpg',
    },
    {
        id: '00000104',
        title: 'Glass Set Water Glass Set of 6 Glass Set for Dining Table Juice Glass Heavy Base Tall Bar Glass - Drinking Glasses for Water, Juice, Beer, Wine, and Cocktails',
        brand: BRANDS.Meldique,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09PG5WHVF',
        image: '00000104.jpg',
    },
    {
        id: '00000105',
        title: 'Glass Whiskey Glasses',
        brand: BRANDS.PrimeWorld,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B086RRQSG6',
        image: '00000105.jpg',
    },
    {
        id: '00000107',
        title: 'Jinelza Deli 1800 ML Water Juice Jug Pot, Stainless Steel Air Tight Cap,Transparent Borosilicate Glass',
        brand: BRANDS.Jinelza,
        categories: [CATEGORIES.GLASSWARE, CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08V95R965',
        image: '00000107.png',
    },
    {
        id: '00000108',
        title: 'Glass Olive Oil Vinegar Dispenser Gravy Boat Kitchen Oil Bottle Stainless Steel Leak-Proof Soy Sauce Vinegar Storage, Oil Bottle, Oil Dispenser, Oil Dispenser for Kitchen',
        brand: BRANDS.Zosoe,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08MNVGTBC',
        image: '00000108.png',
    },
    {
        id: '00000109',
        title: 'Ball Mason Glass Jar & Container with Air Tight Silver Metal Lid, Grocery, Dry Fruit, Spice Storage Jars ',
        brand: BRANDS.Aarav_House,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08KTGKVHC',
        image: '00000109.png',
    },
    {
        id: '00000110',
        title: 'Treo By Milton Pot Jar With Glass Lid, Transparent | Air Tight | Storage Jar | Kitchen Organiser | Modular | Dishwasher Safe',
        brand: BRANDS.Treo,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09BMFSRHW',
        image: '00000110.jpg',
    },
    {
        id: '00000111',
        title: 'Treo By Milton Pot Jar With Glass Lid, Transparent | Air Tight | Storage Jar | Kitchen Organiser | Modular | Dishwasher Safe',
        brand: BRANDS.Treo,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09BMDVR71',
        image: '00000111.jpg',
    },
    {
        id: '00000114',
        title: 'Borosil Classic Glass Jar, Air-Tight Storage Container For Kitchen, Glass Jar For Storing Spices, Snacks, Grains, Dals',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B016BLSUQW',
        image: '00000114.jpg',
    },
    {
        id: '00000115',
        title: 'TREO Glass Storage Jar - 310 Ml, 6 Pieces, Transparent',
        brand: BRANDS.Treo,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07MBYSTN5',
        image: '00000115.jpg',
    },
    {
        id: '00000116',
        title: 'Cube Storage Glass Jar, 2250 ml, Transparent | Storage Jar | Modular | Kitchen Organizer | Modular | Multipurpose Jar | BPA Free',
        brand: BRANDS.Treo,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B017KKIZ2W',
        image: '00000116.jpg',
    },
    {
        id: '00000117',
        title: 'Glass Container with Lid | Small Jars for Kitchen Storage | Ait-Tight | Kitchen Accessories Items and Accessories | Container Jar for Ghee Pickle Dryfruits Tea Coffee Sugar',
        brand: BRANDS.Star_Work,
        categories: [CATEGORIES.GLASSWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07QB7MP49',
        image: '00000117.png',
    },
    {
        id: '00000118',
        title: 'Aluminum Round Shape Cake Mould for Microwave Oven Bakeware (Black)',
        brand: BRANDS.Xacton,
        categories: [CATEGORIES.BAKEWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08CXGRVZY',
        image: '00000118.png',
    },
    {
        id: '00000119',
        title: 'SEIKO Aluminium Baking Round Cake Pan/Mould for Microwave Oven - 8" Diameter x 2.25" Height for 1KG Cake',
        brand: BRANDS.SEIKO,
        categories: [CATEGORIES.BAKEWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B085STMRJQ',
        image: '00000119.png',
    },
    {
        id: '00000120',
        title: 'AmazonBasics Non-Stick Grill Pan (Induction and Gas Compatible), 28 cm, Aluminium, Black',
        brand: BRANDS.Amazon_Basics,
        categories: [CATEGORIES.BAKEWARE, CATEGORIES.INDUCTION_COOKWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07CN6BWKR',
        image: '00000120.jpg',
    },
    {
        id: '00000121',
        title: 'AmazonBasics Nonstick Carbon Steel Baking Bread Pan, 9.5 x 5 Inch',
        brand: BRANDS.Amazon_Basics,
        categories: [CATEGORIES.BAKEWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B073P52PPR',
        image: '00000121.png',
    },
    {
        id: '00000122',
        title: 'Nonstick Bakeware Pumpkin Shape Cake Mold Jelly Pan Round Deep Bundt Baking Mold - Black',
        brand: BRANDS.Zollyss,
        categories: [CATEGORIES.BAKEWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08DKDMXSN',
        image: '00000122.jpg',
    },
    {
        id: '00000123',
        title: 'SEIKO Aluminium Baking Round Cake Pan/Mould for Microwave Oven - 6 Inch',
        brand: BRANDS.SEIKO,
        categories: [CATEGORIES.BAKEWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07JL24Y2K',
        image: '00000123.png',
    },
    {
        id: '00000125',
        title: 'Aluminium Heart Shape (7 inch) & Round Shape (7inch) Cake Mould/Pan',
        brand: BRANDS.Kanshitas_Rasoiware,
        categories: [CATEGORIES.BAKEWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08D6JPQVT',
        image: '00000125.png',
    },
    {
        id: '00000126',
        title: 'Meyer Bakemaster Carbon Steel Non Stick Springform Cake Tin | Baking Pan | Round Cake Mould | Cake Tin Small | Cake Tray for Baking | Removable Cake Mould | Spring Form pan',
        brand: BRANDS.Meyer,
        categories: [CATEGORIES.BAKEWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07HFC5Y6Z',
        image: '00000126.jpg',
    },
    {
        id: '00000127',
        title: 'Meyer Bakemaster Carbon Steel Non-Stick Bakeware 1 lb Loaf Tin (Grey)',
        brand: BRANDS.Meyer,
        categories: [CATEGORIES.BAKEWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07HF9T2S1',
        image: '00000127.jpg',
    },
    {
        id: '00000128',
        title: 'Meyer Bakemaster Carbon Steel Non Stick Square Springform Cake Pan | Cake Mould | Sqaure Cake Tin | Nonstick Bakeware | Baking Essentials | Baking Tray Square Pan, 23 cm, Grey',
        brand: BRANDS.Meyer,
        categories: [CATEGORIES.BAKEWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07HF9FP6G',
        image: '00000128.jpg',
    },
    {
        id: '00000129',
        title: 'Clay Craft Basics - Ceramic Ramekin Bowl/Soufflé Dish for Baking and Serving Puddings, custards or Other Desserts - 180 ml (WHITE)',
        brand: BRANDS.Clay_Craft,
        categories: [CATEGORIES.BAKEWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09FJNRKTY',
        image: '00000129.webp',
    },
    {
        id: '00000130',
        title: 'USA Pan Non Stick Steel Pan, Grey',
        brand: BRANDS.USA_Pan,
        categories: [CATEGORIES.BAKEWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B001TO3CNI',
        image: '00000130.jpg',
    },
    {
        id: '00000131',
        title: 'AmazonBasics 6-Piece Non-stick & Microwave safe Bakeware Set',
        brand: BRANDS.Amazon_Basics,
        categories: [CATEGORIES.BAKEWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0764M2JXY',
        image: '00000131.png',
    },
    {
        id: '00000132',
        title: 'CAROTE Dosa Tawa Non Stick Pan, Granite Tawa for Roti, Flat Dosa Tava, Induction Roti Tawa, 24cm, Anti-Bulge Tawa, Non Stick Tawa',
        brand: BRANDS.Carote,
        categories: [CATEGORIES.NON_STICK_COOKWARE, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.tava, TAGS.tawa, TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B089NPLPG6',
        image: '00000132.png',
    },
    {
        id: '00000133',
        title: 'AmazonBasics Non-Stick Grill Pan (Induction and Gas Compatible)',
        brand: BRANDS.Amazon_Basics,
        categories: [CATEGORIES.NON_STICK_COOKWARE, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B07CN6BWKR',
        image: '00000133.jpg',
    },
    {
        id: '00000134',
        title: 'Hawkins Futura Hard Anodised Saucepan 1.5L, 16 cm, 3.25 mm, Aluminium, Black',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.NON_STICK_COOKWARE],
        tags: [TAGS.pan, TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B00JFUCFCM',
        image: '00000134.png',
    },
    {
        id: '00000135',
        title: 'CAROTE Non Stick Frying Pan, Granite Omlette Egg Pan, Induction & Gas Fry Pan, Fish Pan',
        brand: BRANDS.Carote,
        categories: [CATEGORIES.NON_STICK_COOKWARE, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B0732NXYNS',
        image: '00000135.jpg',
    },
    {
        id: '00000136',
        title: 'CAROTE Non Stick Sauce Pan Tea pan, Granite Saucepan, Milk pan with Lid, Chai Pan Induction & Gas',
        brand: BRANDS.Carote,
        categories: [CATEGORIES.NON_STICK_COOKWARE, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B076P7DQPK',
        image: '00000136.jpg',
    },
    {
        id: '00000137',
        title: 'CAROTE Non Stick Kadai, Induction Kadai, Granite Stone Kadhai with Lid Deep Frying Pan, Biryani Pot',
        brand: BRANDS.Carote,
        categories: [CATEGORIES.NON_STICK_COOKWARE, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B073RWSYSX',
        image: '00000137.jpg',
    },
    {
        id: '00000138',
        title: 'Pigeon Aluminium Nonstick Duo Pack Flat Tawa 250 and Fry Pan',
        brand: BRANDS.Pigeon,
        categories: [CATEGORIES.NON_STICK_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B07KYPZHJ9',
        image: '00000138.png',
    },
    {
        id: '00000140',
        title: 'Hawkins Futura 26 cm Frying Pan, Non Stick Fry Pan with Glass Lid, Frypan',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.NON_STICK_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B00EVQYIQQ',
        image: '00000140.png',
    },
    {
        id: '00000141',
        title: 'Hawkins Futura Hard Anodised Tava',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.NON_STICK_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B003SLDNGG',
        image: '00000141.png',
    },
    {
        id: '00000142',
        title: 'Prestige Omega Deluxe Granite Dosa Tawa, 280 mm, Black, Nonstick Aluminium',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.NON_STICK_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B00QWQ0TCU',
        image: '00000142.png',
    },
    {
        id: '00000143',
        title: 'Prestige Omega Select Plus Mini Handi with SS lid, Aluminium, Black',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.NON_STICK_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B00EICJOXA',
        image: '00000143.png',
    },
    {
        id: '00000144',
        title: 'Stainless Steel Induction Bottom Steamer/Modak/Momo Maker with Glass Lid (2 litres) & Stainless Steel Saucepan with Glass Lid, Induction Base',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.POTS_PANS, CATEGORIES.INDUCTION_COOKWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09SM26T58',
        image: '00000144.jpg',
    },
    {
        id: '00000145',
        title: 'Hawkins Futura 4 Litre Cook n Serve Bowl, Non Stick Saucepan with Glass Lid, Sauce Pan for Cooking and Serving, Black',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.NON_STICK_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B09MVPSJN4',
        image: '00000145.jpg',
    },
    {
        id: '00000146',
        title: 'Hawkins Futura Nonstick Kadhai 2.5 L, 26 cm, 3.25 mm with SS lid',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.NON_STICK_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B003SLDNNO',
        image: '00000146.jpg',
    },
    {
        id: '00000147',
        title: 'Hawkins Futura 3 Litre Cook n Serve Stewpot, Non Stick Pot with Glass Lid, Cooking Pot with Lid, Black',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.NON_STICK_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B00JFUD8J6',
        image: '00000147.jpg',
    },
    {
        id: '00000148',
        title: 'Non Stick Kadhai with Glass Lid (26 cm, Hammertone finish, 3 coat, 2.9mm thickness), Aluminium, Black',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.NON_STICK_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B07P913VF3',
        image: '00000148.jpg',
    },
    {
        id: '00000150',
        title: 'Milton Pro Cook Black Pearl Induction Kadhai',
        brand: BRANDS.Milton,
        categories: [CATEGORIES.NON_STICK_COOKWARE, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.kadai, TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B08KM88Z9H',
        image: '00000150.png',
    },
    {
        id: '00000151',
        title: 'Milton Pro Cook Black Pearl Induction Fry Pan with Glass Lid',
        brand: BRANDS.Milton,
        categories: [CATEGORIES.NON_STICK_COOKWARE, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B08LDCMR9V',
        image: '00000151.jpg',
    },
    {
        id: '00000152',
        title: 'Prestige Omega Select Plus Residue Free Non-Stick Kitchen Set, 3-Pieces',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.NON_STICK_COOKWARE],
        tags: [TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B00EICKVUA',
        image: '00000152.png',
    },
    {
        id: '00000153',
        title: 'Milton Pro Cook Black Pearl Induction Kadhai, 22 cm / 2.1 Litre',
        brand: BRANDS.Milton,
        categories: [CATEGORIES.NON_STICK_COOKWARE, CATEGORIES.INDUCTION_COOKWARE],
        tags: [TAGS.kadai, TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B08KM7D2MS',
        image: '00000153.jpg',
    },
    {
        id: '00000154',
        title: 'Prestige Aluminium Omega Select Plus Non-Stick Flat Base Kadhai with Lid',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.NON_STICK_COOKWARE],
        tags: [TAGS.kadai, TAGS.nonstick, TAGS.non__stick, TAGS.non_stick],
        targetLink: 'https://www.amazon.in/dp/B00AFQYYSS',
        image: '00000154.png',
    },
    {
        id: '00000155',
        title: 'Larah by Borosil Blue Eve Silk Series Opalware Dinner Set, 35 Pieces, White',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.DISHES_PLATES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08G8J3WYR',
        image: '00000155.png',
    },
    {
        id: '00000156',
        title: 'Cello Opalware Dazzle Tropical Lagoon Dinner Set, 18Pcs, White',
        brand: BRANDS.Cello,
        categories: [CATEGORIES.DISHES_PLATES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07JQ1FFYB',
        image: '00000156.jpg',
    },
    {
        id: '00000157',
        title: 'Femora Borosilicate Glass Oval Baking Dish Microwave Oven Safe',
        brand: BRANDS.Femora,
        categories: [CATEGORIES.DISHES_PLATES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08GCS299F',
        image: '00000157.jpg',
    },
    {
        id: '00000158',
        title: 'Borosil Fluted Dish, 1.5 litres, Transparent, Standard',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.DISHES_PLATES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00E97MUUI',
        image: '00000158.jpg',
    },
    {
        id: '00000159',
        title: 'Borosil Glass Cake Dish, 1.2L, Transparent',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.DISHES_PLATES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07FT8TXD2',
        image: '00000159.jpg',
    },
    {
        id: '00000160',
        title: 'AmazonBasics Porcelain Dinner Plate -(White) ',
        brand: BRANDS.Amazon_Basics,
        categories: [CATEGORIES.DISHES_PLATES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0157FD9MS',
        image: '00000160.png',
    },
    {
        id: '00000161',
        title: 'Handmade Ceramic Dinner Plate Set, 4 Pieces (White)',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.DISHES_PLATES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08BVJZRT2',
        image: '00000161.jpg',
    },
    {
        id: '00000162',
        title: 'Caffeine Ceramic Handmade Stoneware Marble Matte Style Soup Plate/Pasta Plate/Maggie Plate/Macaroni Plate 9 inches (1 Pc Microwave & Dishwasher Safe)',
        brand: BRANDS.Caffeine,
        categories: [CATEGORIES.DISHES_PLATES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B079JY5N1T',
        image: '00000162.jpg',
    },
    {
        id: '00000166',
        title: 'Larah by Borosil Green Leaves Silk Series Opalware 19 Pieces Dinner Set, Opal Glass Dinner Plates & Bowls, Crockery Set For Dining',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.DISHES_PLATES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07W55MGGD',
        image: '00000166.png',
    },
    {
        id: '00000167',
        title: 'Blue Eve Silk Series Opalware Dinner Set, 6 Pieces, White, Opal Glass Dinner Plates & Bowls, Crockery Set for Dining',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.DISHES_PLATES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0BT7W288T',
        image: '00000167.png',
    },
    {
        id: '00000169',
        title: 'Borosil Stainless Steel Vacuum Insulated Teapot 750 ml Black',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07K6XQHJ8',
        image: '00000169.png',
    },
    {
        id: '00000170',
        title: 'Borosil - Stainless Steel Teapot- Vacuum Insulated, Red, 500ML',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07VS1GTVB',
        image: '00000170.png',
    },
    {
        id: '00000172',
        title: 'InstaCuppa Stainless Steel Tea Pot with Removable Tea Infuser, Easy to Pour Spout, Cool Touch Handle, Attractive Design, Unbreakable Build, 1.2 Litre',
        brand: BRANDS.InstaCuppa,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08R2VG7V3',
        image: '00000172.png',
    },
    {
        id: '00000173',
        title: 'Clay Craft Basics White Fine Ceramic Tea Pot/Seving Kettle, Coffee Pot - 1000 ml',
        brand: BRANDS.Clay_Craft,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07KX23D8L',
        image: '00000173.png',
    },
    {
        id: '00000174',
        title: 'Mystic Leaf White Ceramic Teapot for 4, Kettle for Home, Kitchen, Tea or Coffee, 750 ML',
        brand: BRANDS.Mystic_Leaf,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0B2ZSSD1L',
        image: '00000174.png',
    },
    {
        id: '00000175',
        title: 'Femora Indian Ceramic Fine Bone China Gold Line Tea Kettle Tea Pot for Office, Home',
        brand: BRANDS.Femora,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07TFD6XP8',
        image: '00000175.jpg',
    },
    {
        id: '00000176',
        title: 'Tesora - Inspired by you Large Premium Electric Kettle 1.8L, Stainless Steel Inner Body, Auto Power Cut, Boil Dry Protection & Cool Touch Double Wall | Portable',
        brand: BRANDS.Tesora,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0BDQZPXJ9',
        image: '00000176.jpg',
    },
    {
        id: '00000177',
        title: 'Amazon Basics Double-Walled Stainless Steel Electric Kettle - 1.7 Litre',
        brand: BRANDS.Amazon_Basics,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B083JK8HYY',
        image: '00000177.jpg',
    },
    {
        id: '00000178',
        title: 'Pigeon by Stovekraft Amaze Plus Electric Kettle (14289) with Stainless Steel Body, 1.5 litre, used for boiling Water, making tea and coffee, instant noodles, soup etc',
        brand: BRANDS.Pigeon,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07WMS7TWB',
        image: '00000178.jpg',
    },
    {
        id: '00000179',
        title: 'Prestige Electric Kettle PKOSS - Steel (1.5Ltr), Black',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B01MQZ7J8K',
        image: '00000179.png',
    },
    {
        id: '00000180',
        title: 'Havells Aqua Plus 1.2 litre Double Wall Kettle / 304 Stainless Steel Inner Body / Cool touch outer body / Wider mouth',
        brand: BRANDS.Havells,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B083GKDRKR',
        image: '00000180.png',
    },
    {
        id: '00000182',
        title: 'Havells Aqua Plus 1.2 litre Double Wall Kettle / 304 Stainless Steel Inner Body / Cool touch outer body / Wider mouth',
        brand: BRANDS.Havells,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07GVLMRBT',
        image: '00000182.png',
    },
    {
        id: '00000183',
        title: 'Prestige PKOSS 1.8 Litre Kettle, Red',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07XRVWCDD',
        image: '00000183.png',
    },
    {
        id: '00000184',
        title: 'Ebony Double Walled Cool Touch Stainless Steel Electric Kettle, 1.8 Litre, with 1500 Watt, boiler for Water, milk, tea, coffee, instant noodles, soup etc.',
        brand: BRANDS.Pigeon,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0969PX7N9',
        image: '00000184.jpg',
    },
    {
        id: '00000185',
        title: 'Amaze Cool Touch Electric Kettle 1.8 L 1500 W | Plastic Outer & Stainless Steel Inside body | Auto shut off Over heating protection | Multipurpose hot water Kettle',
        brand: BRANDS.KENT,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B082KVTRW8',
        image: '00000185.png',
    },
    {
        id: '00000186',
        title: 'Borosil Electric Glass Kettle, Borosilicate Glass, Extra Long Cord, Dry-boil Protection, 360° Rotating Base, 1.8 L, Transparent',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09JLZFG8W',
        image: '00000186.jpg',
    },
    {
        id: '00000187',
        title: 'Inalsa Electric Kettle Prism Inox - 1350 W with LED Illumination & Boro-Silicate Body, 1.8 L Capacity along with Cordless Base',
        brand: BRANDS.Inalsa,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08HLC7Z3G',
        image: '00000187.png',
    },
    {
        id: '00000188',
        title: 'Borosil Eva Cool Touch 0.6 Ltr Stainless Steel Kettle Electric Kettle (0.6 L, Black)',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09BNQ3C7K',
        image: '00000188.png',
    },
    {
        id: '00000189',
        title: 'Borosil Cooltouch Electric Kettle, Stainless Steel Inner Body, Boil Water For Tea, Coffee, Soup, 1.8 L, Silver',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09JLX54LG',
        image: '00000189.jpg',
    },
    {
        id: '00000190',
        title: 'Femora Stainless Steel Grand Jug with Handle - 1.4 L, Silver Black',
        brand: BRANDS.Femora,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0867BKTVV',
        image: '00000190.jpg',
    },
    {
        id: '00000192',
        title: 'Milton Viva Tuff 1500 Insulated Inner Stainless Steel Jug, 1.35 Litre, 1 Piece, Brown | BPA Free | Hot and Cold | Easy to Carry | Leak Proof | Tea Jug | Coffee Jug | Water Jug | Hot Beverages',
        brand: BRANDS.Milton,
        categories: [CATEGORIES.JUGS_KETTLES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00F2G8NS8',
        image: '00000192.jpg',
    },
    {
        id: '00000193',
        title: 'Fine Ceramic Solid White Stack Soup Bowls with Handles - 300ml, 4 Pieces, Multicolour',
        brand: BRANDS.Clay_Craft,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08P56N14T',
        image: '00000193.jpg',
    },
    {
        id: '00000196',
        title: 'Clay Craft Basics - Ceramic Checkered Hammered Soup Maggie and Noodle Cup, 460ml, 2 Pieces, Multicolour, Standard',
        brand: BRANDS.Clay_Craft,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08P55TMM1',
        image: '00000196.webp',
    },
    {
        id: '00000197',
        title: 'Clay Craft Cane Small Carpet Bone China Milk Mug, 350 ml',
        brand: BRANDS.Clay_Craft,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0776N3J11',
        image: '00000197.jpg',
    },
    {
        id: '00000198',
        title: 'Clay Craft Cane Big Carpet Bone China Milk Mug, 450ml',
        brand: BRANDS.Clay_Craft,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0776NDYYL',
        image: '00000198.jpg',
    },
    {
        id: '00000199',
        title: 'Femora Borosilicate Glass Microwave Safe Mixing Bowl',
        brand: BRANDS.Femora,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08GF7WPMH',
        image: '00000199.jpg',
    },

    {
        id: '00000200',
        title: 'Borosil Glass Mixing & Serving Bowls, Oven & Microwave Safe Bowl, 2.5 L, Borosilicate Glass, Clear',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00AYA7QVC',
        image: '00000200.webp',
    },
    {
        id: '00000201',
        title: 'Cello Ornella Glass Mixing Bowl without Lid, 500ml, clear',
        brand: BRANDS.Cello,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00OKEI1RG',
        image: '00000201.png',
    },
    {
        id: '00000203',
        title: 'Borosil Select Glass Mixing & Serving Bowl with Lid, Microwave & Oven Safe, Set of 2 (900 ml + 900 ml) Borosilicate Glass, Clear',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0B9XPFWYR',
        image: '00000203.jpg',
    },
    {
        id: '00000206',
        title: 'Treo by Milton Jelo Designer Glass Bowl, 2240 ml, Transparent',
        brand: BRANDS.Treo,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07VCL4668',
        image: '00000206.png',
    },
    {
        id: '00000207',
        title: 'Cello Ornella Toughened Glass Mixing Bowl without Lid Set of 3 (500ml, 1000ml, 1500ml, Transparent)',
        brand: BRANDS.Cello,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B01A6NLUK4',
        image: '00000207.webp',
    },
    {
        id: '00000208',
        title: 'Shri & Sam Stainless Steel Bowl, 24cm Silver',
        brand: BRANDS.Shri_Sam,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07GFDGP47',
        image: '00000208.jpg',
    },
    {
        id: '00000209',
        title: 'Chai Bina Chain Kaha Re Printed Coffee Mug, Ceramic Printed Quotes Coffee Mug with Keychain, Birthday Gift for Husband, Sister, Mom',
        brand: BRANDS.Tuelip,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07SZQ5BTT',
        image: '00000209.png',
    },
    {
        id: '00000210',
        title: 'Flask Ceramic Graphic/Quote Coffee/Tea Mug Set for Home, Office & Gifting',
        brand: BRANDS.JCPL,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08MYB2HMZ',
        image: '00000210.png',
    },
    {
        id: '00000211',
        title: 'Coffee Mug Let The Battle Begin Cricket Digital Printed Ceramic Coffee Mug, Birthday Gift for Husband, Boyfriend, Dad, Tea & Coffee Cups',
        brand: BRANDS.Tuelip,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07PP7LWMH',
        image: '00000211.png',
    },
    {
        id: '00000212',
        title: 'Flask Ceramic Milk & Coffee Mug, 330ml',
        brand: BRANDS.JCPL,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08P6SGBSB',
        image: '00000212.jpg',
    },
    {
        id: '00000213',
        title: 'Flask Ceramic Milk & Coffee Mug, 330ml',
        brand: BRANDS.JCPL,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08P6L7PZ3',
        image: '00000213.png',
    },
    {
        id: '00000217',
        title: 'Ava Ceramic Coffee Tea Milk Mug',
        brand: BRANDS.Clay_Craft,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08P57LVSG',
        image: '00000217.jpg',
    },
    {
        id: '00000218',
        title: 'Printed Coffee Mug for Gifting(Ceramic,Multicolor,300ml) ',
        brand: BRANDS.TAJNAN,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08V5DS9JR',
        image: '00000218.png',
    },
    {
        id: '00000219',
        title: 'Zindagi Wahi Jeetey Jo Chai Pee Tay Hai Printed Ceramic Coffee Mug for Home and Office',
        brand: BRANDS.Tuelip,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B081VGBQSV',
        image: '00000219.png',
    },
    {
        id: '00000220',
        title: 'Coffee Mug with Handle, 350ML Insulated Stainless Steel Coffee Travel Mug/Tumbler, Double Wall Vacuum Reusable Coffee Cup with Lid, 8x12cm, Black',
        brand: BRANDS.Pepplo,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09165GGQP',
        image: '00000220.jpg',
    },
    {
        id: '00000221',
        title: 'Clay Craft Ceramic Happy Birthday Greeting Milk Mug (Muddy 393, Multicolor, 300ml)',
        brand: BRANDS.Clay_Craft,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08K9VD27B',
        image: '00000221.webp',
    },
    {
        id: '00000222',
        title: 'Ceramic Coffee Tea Milk Mug for Home, Office & Gifting, 330ml, Love Red',
        brand: BRANDS.JCPL,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08MY918VB',
        image: '00000222.png',
    },
    {
        id: '00000223',
        title: 'Donald Ceramic Graphic/Quote Coffee/Tea Mug Set for Home, Office & Gifting',
        brand: BRANDS.JCPL,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08MY9N2GZ',
        image: '00000223.webp',
    },
    {
        id: '00000224',
        title: 'Donald Ceramic Graphic/Quote Coffee/Tea Mug Set for Home, Office & Gifting',
        brand: BRANDS.JCPL,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08MY9MPQV',
        image: '00000224.jpg',
    },
    {
        id: '00000225',
        title: 'Dishwasher and Microwave Safe Glass Coffee Mug with Lid and Steel Spoon',
        brand: BRANDS.PrimeWorld,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B098R9T7TJ',
        image: '00000225.jpg',
    },
    {
        id: '00000226',
        title: 'Flask Ceramic Milk & Coffee Mug, 330ml',
        brand: BRANDS.JCPL,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08P6VCXB4',
        image: '00000226.jpg',
    },
    {
        id: '00000227',
        title: 'Borosil Coffeemate Insulated Mug, Vacuum Insulated Travel Coffee Mug with Lid, 8 Hours Hot and 14 Hours Cold, 300 ml (Stainless Steel)',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07DXHCJYQ',
        image: '00000227.png',
    },
    {
        id: '00000228',
        title: 'Femora Indian Ceramic Handmade Blue Block Print Tea Cup - 6 pcs, 160 ML - Small Serving',
        brand: BRANDS.Femora,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B092CNRXQW',
        image: '00000228.jpg',
    },
    {
        id: '00000229',
        title: 'Femora Indian Ceramic Fine Bone China Floral Gold Line Tea Cup - 6 Pcs, 155 ML',
        brand: BRANDS.Femora,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08PV1FXMX',
        image: '00000229.jpg',
    },
    {
        id: '00000230',
        title: 'Clay Craft Omega Impression Ceramic Gold Line Coffee Mugs/ Tea Cups Set of 6 Pieces, 200ml, White',
        brand: BRANDS.Clay_Craft,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0776MB9ZQ',
        image: '00000230.jpg',
    },
    {
        id: '00000231',
        title: 'Clay Craft Elegant Gold Line Coffee/Tea Cups Set of 6 Perfect for Daily use 180 ml, White',
        brand: BRANDS.Clay_Craft,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08KVH2FF9',
        image: '00000231.jpg',
    },
    {
        id: '00000232',
        title: 'PrimeWorld Glass Coffee Mug with Handle',
        brand: BRANDS.PrimeWorld,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08VBPQMV2',
        image: '00000232.jpg',
    },
    {
        id: '00000233',
        title: 'PrimeWorld Glass Coffee Cappuccino Tea Multipurpose Mug with Handle ',
        brand: BRANDS.PrimeWorld,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07YBJL32Z',
        image: '00000233.jpg',
    },
    {
        id: '00000234',
        title: 'PrimeWorld Glass Tea/Coffee Mug',
        brand: BRANDS.PrimeWorld,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07QHLKMLM',
        image: '00000234.jpg',
    },
    {
        id: '00000235',
        title: 'Femora Borosilicate Glass Double Wall Wide Tea Cup',
        brand: BRANDS.Femora,
        categories: [CATEGORIES.CUPS_MUGS_BOWLS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09492MF6V',
        image: '00000235.jpg',
    },
    {
        id: '00000236',
        title: 'Hawkins 3 Litre Inner Lid Pressure Cooker, Stainless Steel Cooker, Wide Design Pan Cooker, Induction Cooker, Silver',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.PRESSURE_COOKERS, CATEGORIES.INDUCTION_COOKWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B002MPQH8U',
        image: '00000236.png',
    },
    {
        id: '00000237',
        title: 'Hawkins Classic 1.5L Aluminium Inner Lid Pressure Cooker, Silver',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.PRESSURE_COOKERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00SX2YZNK',
        image: '00000237.png',
    },
    {
        id: '00000238',
        title: 'Prestige Nakshatra Plus Svachh Hard Anodised Spillage Control Handi Inner Lid Pressure Cooker, 3 L (Black)',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.PRESSURE_COOKERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0843YLGC9',
        image: '00000238.png',
    },
    {
        id: '00000239',
        title: 'Hawkins Contura 3L Aluminium Inner Lid Pressure Cooker (Silver)',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.PRESSURE_COOKERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00NRT7ZUO',
        image: '00000239.png',
    },
    {
        id: '00000240',
        title: 'Hawkins Contura Aluminium Inner Lid 3 Litre Pressure Cooker, Ceramic Coated Handi Cooker, Tomato Red',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.PRESSURE_COOKERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B013FZ0VBE',
        image: '00000240.png',
    },
    {
        id: '00000241',
        title: 'Pigeon By Stovekraft Favourite Aluminium Pressure Cooker with Outer Lid Gas Stove Compatible 3 Litre Capacity for Healthy Cooking (Silver)',
        brand: BRANDS.Pigeon,
        categories: [CATEGORIES.PRESSURE_COOKERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00VK5MCMI',
        image: '00000241.png',
    },
    {
        id: '00000242',
        title: 'Pigeon By Stovekraft Favourite Aluminium Pressure Cooker with Outer Lid Induction and Gas Stove Compatible 5 Litre Capacity for Healthy Cooking (Silver)',
        brand: BRANDS.Pigeon,
        categories: [CATEGORIES.PRESSURE_COOKERS, CATEGORIES.INDUCTION_COOKWARE],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00TE7QY7S',
        image: '00000242.png',
    },
    {
        id: '00000243',
        title: 'Prestige Svachh, 20255, 5 L, Deep Pressure Pan, with Deep Lid for Spillage Control, Stainless Steel, Silver, Outer Lid',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.PRESSURE_COOKERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B084KYJ1CV',
        image: '00000243.png',
    },
    {
        id: '00000244',
        title: 'Prestige Popular Stainless Steel Outer Lid Pressure Cooker, 3 Litres, Silver',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.PRESSURE_COOKERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B074DCTWFW',
        image: '00000244.png',
    },
    {
        id: '00000245',
        title: 'Prestige Svachh Nakshatra Plus Hard Anodised Aluminium Inner Lid 5 Litre Pressure Handi, Black',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.PRESSURE_COOKERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0843YZ58G',
        image: '00000245.png',
    },
    {
        id: '00000246',
        title: 'Prestige Svachh, 10752, 3 L, Nakshatra Duo Red Handi, with Deep Lid for Spillage Control, Aluminium, Inner Lid',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.PRESSURE_COOKERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B085YG9V27',
        image: '00000246.png',
    },
    {
        id: '00000247',
        title: 'Hawkins 3 Litre Contura Black Pressure Cooker, Hard Anodised Inner Lid Cooker, Handi Cooker, Black',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.PRESSURE_COOKERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00SX03I80',
        image: '00000247.png',
    },
    {
        id: '00000248',
        title: 'Hawkins Contura Hard Anodised Aluminium Inner Lid Pressure Cooker, 2 Liters, Black',
        brand: BRANDS.Hawkins,
        categories: [CATEGORIES.PRESSURE_COOKERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00SX03I5I',
        image: '00000248.png',
    },
    {
        id: '00000249',
        title: 'Treo By Milton Cube Storage Glass Jar, Set of 6, 580 ml Each, Transparent | BPA Free | Storage Jar | Kitchen Organizer | Modular | Multipurpose Jar',
        brand: BRANDS.Treo,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07TXL1K7V',
        image: '00000249.png',
    },
    {
        id: '00000250',
        title: 'TREO Glass Storage Jar - 310 Ml, 6 Pieces, Transparent',
        brand: BRANDS.Treo,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07MBYSTN5',
        image: '00000250.png',
    },
    {
        id: '00000251',
        title: 'Plastic Storage Containers With Metal Finish Lids- 15 pieces, Silver',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07HCLTFRN',
        image: '00000251.jpg',
    },
    {
        id: '00000252',
        title: 'Fridge Storage plastic Boxes, Fridge Organizer Case With Removable Drain Plate Stackable Storage Containers Keep Fresh For Storing Fish, Meat, Vegetables',
        brand: BRANDS.SHAYONAM,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09NQDQQ55',
        image: '00000252.jpg',
    },
    {
        id: '00000253',
        title: 'Cello Checkers Plastic PET Canister Set, 18 Pieces, Clear',
        brand: BRANDS.Cello,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B01LYBZX6Y',
        image: '00000253.png',
    },
    {
        id: '00000254',
        title: 'Checkered Jar Container Set of 18, 1000 ml, 500 ml, 250 ml, Transparent, Plastic',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07FXT5HBD',
        image: '00000254.png',
    },
    {
        id: '00000255',
        title: 'Twister Airtight Plastic Jar, Set of 6 (Geometric Pattern) with Woven Basket Holder (Black)',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07P94VJVS',
        image: '00000255.jpg',
    },
    {
        id: '00000256',
        title: 'Plantex Heavy-Duty Stainless-Steel Dish Drainer Basket for Kitchen Utensils/Dish Drying Rack/Plate Stand/Bartan Basket',
        brand: BRANDS.Plantex,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07CWN568Z',
        image: '00000256.png',
    },
    {
        id: '00000257',
        title: 'Plantex Stainless Steel 2-Tier Kitchen Rack/Spice Shelf/Kitchen/Pantry Storage Organizer',
        brand: BRANDS.Plantex,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07YG895HX',
        image: '00000257.jpg',
    },
    {
        id: '00000258',
        title: 'Plantex Stainless Steel Triple Step Swing Fruit and Vegetable Basket for Kitchen and Dining Table',
        brand: BRANDS.Plantex,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07H1VR9WM',
        image: '00000258.jpg',
    },
    {
        id: '00000259',
        title: 'Planet Heavy Stainless Steel Nickel Chrome Plated Vegetable and Fruit Bowl Basket',
        brand: BRANDS.Plantex,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B06XSNK7VB',
        image: '00000259.png',
    },
    {
        id: '00000260',
        title: 'Plantex Elegant High Grade Steel 3-Tier Fruit & Vegetable Basket for Dining Table/Kitchen',
        brand: BRANDS.Plantex,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08VWCTVMC',
        image: '00000260.jpg',
    },
    {
        id: '00000261',
        title: 'Ebee 4 Layer Storage Organizer Slim Plastic Rack Shelf with Wheels',
        brand: BRANDS.Ebee,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07SCR4Q6P',
        image: '00000261.jpg',
    },
    {
        id: '00000262',
        title: 'Lyrovo Spice Rack Organizer for Counter top 2-Tier Metal Spice Organizer Standing Rack Shelf Storage Holder with Shelf Liner for Kitchen Cabinet Pantry Bathroom Office',
        brand: BRANDS.Lyrovo,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09WF13B3Z',
        image: '00000262.png',
    },
    {
        id: '00000263',
        title: 'Amazon Brand - Solimo Modular Plastic Storage Containers with Lid, Set of 6, 2.4L, Blue',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07P5XLWDT',
        image: '00000263.jpg',
    },
    {
        id: '00000264',
        title: 'Plastic Self Draining Tableware Storage Box - Spoon, Knife, Fork, Chopstick, Cutlery Holder/Organizer Stand for Kitchen, Dining Table',
        brand: BRANDS.KITOME,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07WHSD7HZ',
        image: '00000264.png',
    },
    {
        id: '00000266',
        title: 'Ginoya Brothers Table Top Wooden Mug Holder Kitchen Stand Organizer for Drinkware Drying Rack with 6 Hook',
        brand: BRANDS.Ginoya_Brothers,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B081T5FNDM',
        image: '00000266.jpg',
    },
    {
        id: '00000268',
        title: 'Decorlay Iron 2-Tier Kitchen Organizer Spice Rack Kitchen Cabinets Storage Racks | Bartan Stand | Organizer Storage Rack For Kitchen - White',
        brand: BRANDS.Decorlay,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0847BJGF2',
        image: '00000268.jpg',
    },
    {
        id: '00000269',
        title: 'EverEx Stainless Steel Kitchen Rack Stand Container Organizer Organiser Storage Shelf Shelves Holder Trolley Basket for Spice Boxes Dabba Bartan Utensils Dishes for Home, 2-Tier, Large, Silver',
        brand: BRANDS.EverEx,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0827HJZBB',
        image: '00000269.webp',
    },
    {
        id: '00000270',
        title: 'Amazon Brand - Solimo Revolving Plastic Spice Rack set (16 pieces, Silver)',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07PBBRWFG',
        image: '00000270.jpg',
    },
    {
        id: '00000271',
        title: 'Plastic Dish Drainer and Drying Rack for Kitchen Blue',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08BVNK2XL',
        image: '00000271.png',
    },
    {
        id: '00000272',
        title: 'JRM 360° Rotating Organizer Tray Multi- Function Rotating Tray/Kitchen Organizer/Cosmetics',
        brand: BRANDS.JRM,
        categories: [CATEGORIES.STORAGE_CONTAINERS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0B97GHMJ5',
        image: '00000272.jpg',
    },
    {
        id: '00000273',
        title: 'Nutricook Air Fryer 2, 1700 Watts, Digital Control Panel Display, 10 Preset Programs with built-in Preheat function, 5.5 liter Black',
        brand: BRANDS.Nutricook,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B091NRLB9V',
        image: '00000273.jpg',
    },
    {
        id: '00000274',
        title: 'Instant Pot Air Fryer Vortex 6QT Clear Cook Touch Control Panel 360° Uses 95 % less Oil 6-in-1 Appliance: Air Fry, Roast, Broil, Bake, Reheat, and Dehydrate Vortex 6QT ClearCook, 1700 watts',
        brand: BRANDS.Instant_Pot,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B096N3FTZP',
        image: '00000274.jpg',
    },
    {
        id: '00000308',
        title: 'Wellspire Instant Pot with #304 Stainless Steel Inner Pot, Pressure Cook, Sauté, Steam and more',
        brand: BRANDS.Wellspire,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0BDRMDGL8',
        image: '00000308.png',
    },
    {
        id: '00000275',
        title: 'Prestige IRIS Plus 750 watt mixer grinder',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08CFJBZRK',
        image: '00000275.png',
    },
    {
        id: '00000276',
        title: 'Prestige Delight PRWO Electric Rice Cooker',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B01M6453MB',
        image: '00000276.png',
    },
    {
        id: '00000277',
        title: 'Butterfly Smart Wet Grinder, 2L (White) with Coconut Scrapper Attachment',
        brand: BRANDS.Butterfly,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B078HG2ZPS',
        image: '00000277.png',
    },
    {
        id: '00000278',
        title: 'Elgi Ultra Grind+ Gold Table Top Wet Grinder, 2L (White/ Purple)',
        brand: BRANDS.Elgi,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00P4GQYDM',
        image: '00000278.png',
    },
    {
        id: '00000279',
        title: 'Prestige PWG 07 Wet Grinder, 2L (Multicolor) with Coconut Scraper and Atta Kneader Attachments',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B082ZQ4479',
        image: '00000279.png',
    },
    {
        id: '00000280',
        title: 'Morphy Richards 300-Watt Hand Mixer',
        brand: BRANDS.Morphy_Richards,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B008P7I660',
        image: '00000280.png',
    },
    {
        id: '00000281',
        title: 'Philips 300 Watt Lightweight Hand Mixer, Blender with 5 speed control settings',
        brand: BRANDS.Philips,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07GDR7VZ3',
        image: '00000281.png',
    },
    {
        id: '00000282',
        title: 'KENT 16051 Hand Blender 300 W | 5 Variable Speed Control | Multiple Beaters & Dough Hooks | Turbo Function',
        brand: BRANDS.KENT,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07Y5FDPKV',
        image: '00000282.png',
    },
    {
        id: '00000283',
        title: 'Borosil 850-Watt Krispy Pop-up Toaster (Black)',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00T5UWWU2',
        image: '00000283.png',
    },
    {
        id: '00000284',
        title: 'KENT 16020 Instant Egg Boiler | 3 Boiling Modes | Upto 7 Egg at a Time | Automatic Operation | Easy and Quick Operation | Overheat Protection',
        brand: BRANDS.KENT,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0791GQRPF',
        image: '00000284.jpg',
    },
    {
        id: '00000285',
        title: 'Egg Boiler Electric Automatic Off 7 Egg Poacher for Steaming, Cooking Also Boiling and Frying',
        brand: BRANDS.Simxen,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07H3WDC4X',
        image: '00000285.png',
    },
    {
        id: '00000286',
        title: 'Egg Boiler 400 Watts | Boils Upto 6 Eggs at a Time | 3 Boiling Modes | Stainless Steel Body and Heating Plate | Automatic Turn-Off',
        brand: BRANDS.KENT,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08JQZP7G1',
        image: '00000286.png',
    },
    {
        id: '00000287',
        title: 'Wipro Vesta 360 Watts 4 in 1 Multicooker Egg Boiler|Concurrent Cooking|Boils up to 14 Eggs at a time |Steam Rice, Poach Eggs, Cook Vegetable & Boil Egg|3 Boiling Modes',
        brand: BRANDS.Wipro,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09YHRR949',
        image: '00000287.png',
    },
    {
        id: '00000288',
        title: 'Prestige Sandwich Maker PGMFD 01, Black',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07S851WX5',
        image: '00000288.png',
    },
    {
        id: '00000289',
        title: 'KENT 16025 Sandwich Grill 700W | Non-Toxic Ceramic Coating | Automatic Temperature Cut-off with LED Indicator | Adjustable Height Control, Metallic Silver, Standard',
        brand: BRANDS.KENT,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07GWTWFS2',
        image: '00000289.png',
    },
    {
        id: '00000290',
        title: 'AGARO Venus 2 Slice Pop up toaster, White, Medium',
        brand: BRANDS.AGARO,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0977C8QJR',
        image: '00000290.png',
    },
    {
        id: '00000291',
        title: 'Pigeon 2 Slice Auto Pop up Toaster. A Smart Bread Toaster for Your Home',
        brand: BRANDS.Pigeon,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B06Y47J3FY',
        image: '00000291.png',
    },
    {
        id: '00000292',
        title: 'Philips Daily Collection HD2582/00 830-Watt 2-Slice Pop-up Toaster ',
        brand: BRANDS.Philips,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B071VNHMX2',
        image: '00000292.png',
    },
    {
        id: '00000293',
        title: 'Borosil Prime Grill Sandwich Maker',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07JZSG42Y',
        image: '00000293.jpg',
    },
    {
        id: '00000294',
        title: 'NutriPro Juicer Mixer Grinder - Smoothie Maker - 500 Watts (3 Jar, Silver)',
        brand: BRANDS.Nutripro,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09J2SCVQT',
        image: '00000294.png',
    },
    {
        id: '00000295',
        title: 'Wonderchef Nutri-blend Mixer, Grinder & Blender | Powerful 400W 22000 RPM motor | Stainless steel Blades | 2 unbreakable jars',
        brand: BRANDS.Wonderchef,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00W56GLOQ',
        image: '00000295.png',
    },
    {
        id: '00000296',
        title: 'Pigeon Brewster Coffee Maker, 600 Watt, 4 Cups Drip Coffee maker (Black)',
        brand: BRANDS.Pigeon,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07WJ4NG21',
        image: '00000296.png',
    },
    {
        id: '00000297',
        title: 'Morphy Richards Europa Drip 600-Watt 6-cup Drip Coffee Maker, Gloss Black, Regular',
        brand: BRANDS.Morphy_Richards,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09G9MVJ64',
        image: '00000297.png',
    },
    {
        id: '00000298',
        title: 'PHILIPS Drip Coffee Maker, 0.6 L, Ideal for 2-7 Cups, 750W, Black, Medium',
        brand: BRANDS.Philips,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09H7JDJCW',
        image: '00000298.png',
    },
    {
        id: '00000299',
        title: 'PHILIPS Hand Blender, 250W',
        brand: BRANDS.Philips,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00YQLG7GK',
        image: '00000299.png',
    },
    {
        id: '00000300',
        title: 'Inalsa Hand Blender| Hand Mixer|Beater - Easy Mix, Powerful 250 Watt Motor | Variable 7 Speed Control',
        brand: BRANDS.Inalsa,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B075K76YW1',
        image: '00000300.png',
    },
    {
        id: '00000301',
        title: 'Borosil Smartmix 300-Watt Hand Mixer ',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07BJH2MN2',
        image: '00000301.jpg',
    },
    {
        id: '00000302',
        title: 'Cookwell Bullet Mixer Grinder (5 Jars, 3 Blades, Silver)',
        brand: BRANDS.Cookwell,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B097XJQZ8H',
        image: '00000302.png',
    },
    {
        id: '00000303',
        title: 'TTK Prestige Limited Orion Mixer Grinder 500 Watts, 3 Jars (1200ml, 1000ml, 500ml) ',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09ZDVL7L8',
        image: '00000303.png',
    },
    {
        id: '00000304',
        title: 'Morphy Richards Icon Superb 750W Mixer Grinder, 4 Jars, Silver and Black',
        brand: BRANDS.Morphy_Richards,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08H673XKN',
        image: '00000304.png',
    },
    {
        id: '00000305',
        title: 'HUL Pureit Advanced Pro Mineral RO+UV 6 stage wall mounted counter top black 7L Water Purifier',
        brand: BRANDS.HUL_Pureit,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08B27R8MP',
        image: '00000305.png',
    },
    {
        id: '00000306',
        title: 'Livpure Glo RO+UV+Mineraliser - 7 L Storage, 15 lph Electric Water Purifier for Home, 6 Stage Advanced Purification',
        brand: BRANDS.Livpure,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B01JLRXIQK',
        image: '00000306.png',
    },
    {
        id: '00000307',
        title: 'Aquaguard Aura RO+UV+UF+Taste Adjuster(MTDS) with Active Copper & Zinc 7L water purifier,8 stages of purification',
        brand: BRANDS.Eureka_Forbes,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B096NTB9XT',
        image: '00000307.png',
    },
    {
        id: '00000334',
        title: 'Instant Pot Air Fryer, Vortex 8 Litre ClearCook Dual Basket, Touch Control Panel, 360° EvenCrisp™ Technology, Uses 95 % less Oil, 6-in-1 Appliance: Air Fry, Roast, Broil, Bake, Reheat, and Dehydrate (Vortex 8 Litre ClearCook)',
        brand: BRANDS.Instant_Pot,
        categories: [CATEGORIES.ESSENTIAL_APPLIANCES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0B53CGS6Z',
        image: '00000334.jpg',
    },
    {
        id: '00000315',
        title: 'Philips Viva Collection 2100-Watt Induction Cooktop (Black)',
        brand: BRANDS.Philips,
        categories: [CATEGORIES.STOVES_COOKTOPS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00EDJJ7FS',
        image: '00000315.png',
    },
    {
        id: '00000309',
        title: 'Usha Cookjoy 2000 Watt Induction Cooktop (Black)',
        brand: BRANDS.Usha,
        categories: [CATEGORIES.STOVES_COOKTOPS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09V1JGTMZ',
        image: '00000309.png',
    },
    {
        id: '00000310',
        title: 'Prestige 2200 Watts Indian Menu Options Induction Cooktop, Black',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.STOVES_COOKTOPS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07L12RZXL',
        image: '00000310.png',
    },
    {
        id: '00000319',
        title: 'Pigeon by Stovekraft Cruise 1800 watt Induction Cooktop (Black)',
        brand: BRANDS.Pigeon,
        categories: [CATEGORIES.STOVES_COOKTOPS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B01GFTEV5Y',
        image: '00000319.png',
    },
    {
        id: '00000311',
        title: 'Elica Vetro Glass Top 3 Burner Gas Stove',
        brand: BRANDS.Elica,
        categories: [CATEGORIES.STOVES_COOKTOPS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07C1ZMK8B',
        image: '00000311.png',
    },
    {
        id: '00000312',
        title: 'Prestige IRIS LPG Gas Stove, 2 Burner, Black, Powder coater Mild Steel with Glass Top',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.STOVES_COOKTOPS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08HNQ3PBV',
        image: '00000312.png',
    },
    {
        id: '00000313',
        title: 'Prestige IRIS LPG Gas Stove, 3 Burner, Black',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.STOVES_COOKTOPS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08HNHNHSS',
        image: '00000313.png',
    },
    {
        id: '00000314',
        title: 'Prestige Magic 4 Burner Gas Stove, Black Colour, Glass',
        brand: BRANDS.Prestige,
        categories: [CATEGORIES.STOVES_COOKTOPS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08CFD6PL9',
        image: '00000314.png',
    },
    {
        id: '00000316',
        title: 'Glen 3 Burner Lpg Toughened Gas Stove Mirror Finished Glass With High Flame Forged Brass Burner, 5 Years Warranty On Glass, Valve And Burners, Silver Finish',
        brand: BRANDS.Glen,
        categories: [CATEGORIES.STOVES_COOKTOPS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07YY7RBR5',
        image: '00000316.png',
    },
    {
        id: '00000317',
        title: 'Whirlpool Hob 4 Burner Auto Ignition Gas Stove (Elite Hybrid 704 MT Brass Gas Hob)',
        brand: BRANDS.Whirlpool,
        categories: [CATEGORIES.STOVES_COOKTOPS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07YY8LV4N',
        image: '00000317.png',
    },
    {
        id: '00000318',
        title: 'Whirlpool Hob 3 Burner Auto Ignition Gas Stove (Elite Hybrid 703 MT Brass Gas Hob)',
        brand: BRANDS.Whirlpool,
        categories: [CATEGORIES.STOVES_COOKTOPS],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07YY7RBR5',
        image: '00000318.png',
    },
    {
        id: '00000320',
        title: 'IFB 20 L Convection Microwave Oven (20SC2, Metallic Silver, With Starter Kit)',
        brand: BRANDS.IFB,
        categories: [CATEGORIES.OVEN_OTG],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00A7PGI18',
        image: '00000320.png',
    },
    {
        id: '00000321',
        title: 'Philips Digital Oven Toaster Grill, 25 Litre OTG, 1500 Watt with Opti Temp Technology, Chamber light and 10 preset menus, Inner Lamp (Grey)',
        brand: BRANDS.Philips,
        categories: [CATEGORIES.OVEN_OTG],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07P1BR7L8',
        image: '00000321.png',
    },
    {
        id: '00000322',
        title: 'Samsung 28 L Convection Microwave Oven',
        brand: BRANDS.Samsung,
        categories: [CATEGORIES.OVEN_OTG],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09XBMXQ34',
        image: '00000322.png',
    },
    {
        id: '00000323',
        title: 'Samsung 28 L Convection Microwave Oven, Black with Magnolia Pattern',
        brand: BRANDS.Samsung,
        categories: [CATEGORIES.OVEN_OTG],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09XBPGYV8',
        image: '00000323.png',
    },
    {
        id: '00000324',
        title: 'LG 32L Charcoal Convection Microwave Oven',
        brand: BRANDS.LG,
        categories: [CATEGORIES.OVEN_OTG],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07XZ1BW5P',
        image: '00000324.png',
    },
    {
        id: '00000325',
        title: 'Samsung 28 L Convection Microwave Oven',
        brand: BRANDS.Samsung,
        categories: [CATEGORIES.OVEN_OTG],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B01LPQAXOU',
        image: '00000325.png',
    },
    {
        id: '00000326',
        title: 'LG 28L Convection Microwave Oven (Silver)',
        brand: BRANDS.LG,
        categories: [CATEGORIES.OVEN_OTG],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B01LW06I1S',
        image: '00000326.png',
    },
    {
        id: '00000328',
        title: 'Panasonic 23L Convection Microwave Oven',
        brand: BRANDS.Panasonic,
        categories: [CATEGORIES.OVEN_OTG],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00KPSSPZY',
        image: '00000328.png',
    },
    {
        id: '00000329',
        title: 'LG 28L Convection Microwave Oven (Black)',
        brand: BRANDS.LG,
        categories: [CATEGORIES.OVEN_OTG],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07MC84GZH',
        image: '00000329.png',
    },
    {
        id: '00000330',
        title: 'Borosil DigiPro 38L Digital Oven Toaster & Griller, Convection Mode, 6 Pre-set Menus, Temperature Control, Silver',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.OVEN_OTG],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07WZGDFRM',
        image: '00000330.jpg',
    },
    {
        id: '00000331',
        title: 'Bajaj Majesty 16L Oven Toaster Griller (16 Litres OTG) Baking & Grilling Accessories, Oven for Kitchen with Stainless Steel Body',
        brand: BRANDS.Bajaj,
        categories: [CATEGORIES.OVEN_OTG],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B009P2KRAW',
        image: '00000331.png',
    },
    {
        id: '00000332',
        title: 'Borosil Prima 42 Liters Oven Toaster & Grill, Motorised Rotisserie & Convection Heating, 6 Heating Modes, Silver Top & Chrome Front',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.OVEN_OTG],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B01M04ZN32',
        image: '00000332.png',
    },
    {
        id: '00000333',
        title: 'Borosil Prima 60 Liters Oven Toaster & Grill, Motorised Rotisserie & Convection Heating, 12 Heating Modes, Black',
        brand: BRANDS.Borosil,
        categories: [CATEGORIES.OVEN_OTG],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07RTJY15J',
        image: '00000333.jpg',
    },
    {
        id: '00000335',
        title: 'Wooden Cutlery Holder / Caddy, 10.8x7.7x5.6IN, Brown',
        brand: BRANDS.Brick_Brown,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07T6J9G4L',
        image: '00000335.jpg',
    },
    {
        id: '00000336',
        title: 'Sheesham Wood Rectangle Shape Serving Trays for Dining Table',
        brand: BRANDS.Wood_Art_Store,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08C5HJRQT',
        image: '00000336.png',
    },
    {
        id: '00000339',
        title: 'Yellow Weaves Extra Large Natural Bamboo Wood Cutting Board/Chopping Board with Juice Groove for Kitchen',
        brand: BRANDS.Yellow_Weaves,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B091T6S786',
        image: '00000339.png',
    },
    {
        id: '00000342',
        title: 'Jaipur Ace Handcrafted Sheesham Wooden Pure Rosewood Wooden Chakla Belan Rolling Pin Board/Roti Maker/Chakla Belan/Chapati Maker/papad chakla for Home & Kitchen Combo Set for Kitchen',
        brand: BRANDS.Jaipur_Ace,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [TAGS.belan, TAGS.chakla],
        targetLink: 'https://www.amazon.in/dp/B09QB3QK9B',
        image: '00000342.png',
    },
    {
        id: '00000343',
        title: 'Rollyware 100% Stainless Steel Joint Less Design Polpat, Chakla 25% Less Effort Working The Dough, Upgrade Your Baking, Non-Stick & Zero Maintenance',
        brand: BRANDS.Rollyware,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [TAGS.belan, TAGS.chakla],
        targetLink: 'https://www.amazon.in/dp/B081FCZJBH',
        image: '00000343.png',
    },
    {
        id: '00000344',
        title: 'Sharda Metals Stainless Steel Belan Rolling Pin for Kitchen | Steel Belan for Roti | Steel Rolling Pin | Chapati Roti Roller Rolling Pin',
        brand: BRANDS.Sharda_Metals,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [TAGS.belan, TAGS.chakla],
        targetLink: 'https://www.amazon.in/dp/B07KX1W5Q8',
        image: '00000344.png',
    },
    {
        id: '00000345',
        title: 'Jaipur Ace Ring Base Rolling Pin Board Roti Maker Chakla 10 Inch (Green Marble Chakla) (Green Marble Chakla)',
        brand: BRANDS.Jaipur_Ace,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [TAGS.belan, TAGS.chakla],
        targetLink: 'https://www.amazon.in/dp/B07RBXMQP6',
        image: '00000345.jpg',
    },
    {
        id: '00000346',
        title: 'Wooden Mortar and Pestle set//Wooden Imam Dasta //Wooden Ohkli Musal//Wooden Kharal//khalbatta//For Mixer and Grinder Spices and Herbs Kitchen in Sheesham Wood',
        brand: BRANDS.Treegift,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09PLKCQLK',
        image: '00000346.png',
    },
    {
        id: '00000347',
        title: 'Pure Brass Imam Dasta/Mortar and Pestle Set/Ohkli Musal/Kharal- 4 Inches',
        brand: BRANDS.Pure_Source,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08CRYLLVR',
        image: '00000347.png',
    },
    {
        id: '00000348',
        title: 'Mortar and Pestle Set, kharad, Masher Spice Mixer/Okhli and musle/Kharal for Kitchen 4 inches, Green Colour',
        brand: BRANDS.Simran_Handicrafts,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B075SM9CT7',
        image: '00000348.png',
    },
    {
        id: '00000350',
        title: 'Jaipur Ace Natural White Marble Mortar and Pestle Set, Imam Dasta, Ohkli Musal, Kharal/Khalbatta or Mixer and Grinder Set for Kitchen',
        brand: BRANDS.Jaipur_Ace,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08G1FYLG1',
        image: '00000350.jpg',
    },
    {
        id: '00000351',
        title: 'Amazon Brand - Solimo Stainless Steel Kitchen Press/Noodles/Murukku maker',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07P82LZWZ',
        image: '00000351.png',
    },
    {
        id: '00000352',
        title: 'Tiara Multipurpose Professional Plastic Vegetable Peeler/Slicer/Shredder 3 In 1 Fortune Set',
        brand: BRANDS.Tiara,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08NYQS5W5',
        image: '00000352.png',
    },
    {
        id: '00000353',
        title: 'Victorinox, Swiss Classic Multipurpose PEELER/ Vegetable SCRAPPER Double Edge Serrated',
        brand: BRANDS.Victorinox,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00AETY5O4',
        image: '00000353.png',
    },
    {
        id: '00000355',
        title: 'Amazon Brand - Solimo Food Grade Stainless Steel Lemon Squeezer',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07PBBWRPY',
        image: '00000355.png',
    },
    {
        id: '00000356',
        title: 'Signoraware Lemon Squeezer with Bottle Opener Food Grade Stainless Steel',
        brand: BRANDS.Signoraware,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08CTPHHKG',
        image: '00000356.png',
    },
    {
        id: '00000358',
        title: 'Frying Turner Double Sided Spatula Multi-Functional Stainless Steel Food Flipping Clip Steak Tong Food Clamp',
        brand: BRANDS.Giffy,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09C1WN62R',
        image: '00000358.jpg',
    },
    {
        id: '00000360',
        title: 'Dynore Stainless Steel Cake and Pizza Stand, Steel',
        brand: BRANDS.Dynore,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B01F7B2YD2',
        image: '00000360.png',
    },
    {
        id: '00000363',
        title: 'Stainless Steel 5 in 1 Grater and Slicer with 4 Sides for Cheese, Vegetables, Ginger, Garlic',
        brand: BRANDS.Kitchen_Connect,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09716VKYX',
        image: '00000363.png',
    },
    {
        id: '00000364',
        title: 'Ajanta Stainless Steel Slicer and Grater for Kitchen, Silver',
        brand: BRANDS.Ajanta,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09JFZPKNB',
        image: '00000364.png',
    },
    {
        id: '00000365',
        title: 'Plantex Stainless Steel - Matt Finish Pakkad - Kitchen Pincers - Sandasi - Sansi - Regular Kitchen Tool',
        brand: BRANDS.Plantex,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0793T1YN5',
        image: '00000365.png',
    },
    {
        id: '00000366',
        title: 'Amazon Brand - Solimo Premium High-Carbon Stainless Steel Kitchen Knife Set, 3-Pieces, Silver',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0728HGGPC',
        image: '00000366.png',
    },
    {
        id: '00000367',
        title: 'Amazon Brand - Solimo Premium High-Carbon Stainless Steel Santoku Knife, Black',
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B079YFGSXQ',
        image: '00000367.png',
    },
    {
        id: '00000368',
        title: "Amazon Brand - Solimo Premium Stainless Steel 8 inch blade Chef's Knife",
        brand: BRANDS.Amazon_Solimo,
        categories: [CATEGORIES.TOOLS_ACCESSORIES],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B071SDYY6N',
        image: '00000368.png',
    },
    {
        id: '00000369',
        title: 'The Secret Ingredient is Always Love Kitchen Quote Printed Home Wall Door Sign Hanging (Wooden, 11x9 Inch,Brown)',
        brand: BRANDS.Generic,
        categories: [CATEGORIES.KITCHEN_DECOR],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08RXDBV7S',
        image: '00000369.png',
    },
    {
        id: '00000370',
        title: 'KITOME Plastic Self Draining Tableware Storage Box - Spoon, Knife, Fork, Chopstick, Cutlery Holder/Organizer Stand for Kitchen, Dining Table (White)',
        brand: BRANDS.KITOME,
        categories: [CATEGORIES.KITCHEN_DECOR],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07WHSD7HZ',
        image: '00000370.png',
    },
    {
        id: '00000371',
        title: 'pepplo Porcelain Condiment Jar Spice Container with Lids - Bamboo Cap Holder Spot, Ceramic Serving Spoon, Wooden Tray, Kitchen, Counter',
        brand: BRANDS.Pepplo,
        categories: [CATEGORIES.KITCHEN_DECOR],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B081C3JKVV',
        image: '00000371.png',
    },
    {
        id: '00000372',
        title: 'Artvibes MDF Wooden Nature Designer Wall Hanging',
        brand: BRANDS.Clarco,
        categories: [CATEGORIES.KITCHEN_DECOR],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09BZLGWFB',
        image: '00000372.jpg',
    },
    {
        id: '00000373',
        title: 'Set of 5 wall photo frame/art prints for Dinning Table, Kitchen or Eating area with MDF cutlery and Chalk board',
        brand: BRANDS.Art_Street,
        categories: [CATEGORIES.KITCHEN_DECOR],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B07K2S1MYG',
        image: '00000373.jpg',
    },
    {
        id: '00000374',
        title: 'Kitchen Spices Motivational Funny Quotes Wall Art Painting, Framed Posters, 20 X 20 inches, 10.0 X 10.0 inches Each, Multicolor, Synthetic Wood, Set of 4',
        brand: BRANDS.The_Generation_X_Art_House,
        categories: [CATEGORIES.KITCHEN_DECOR],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B08X7L1XTW',
        image: '00000374.jpg',
    },
    {
        id: '00000376',
        title: 'Decorative Wall Hanging Wooden Art Decoration item for Living Room | Bedroom | Home Decor | Gifts | Kitchen Quotes items | Wall Art For Hall',
        brand: BRANDS.ArtVibes,
        categories: [CATEGORIES.KITCHEN_DECOR],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0B1X5GTQD',
        image: '00000376.png',
    },
    {
        id: '00000377',
        title: 'In This Home Decorative Wall Art MDF Wooden Hanger for Living Room | Bedroom | Artworks Decor | Office | Gift | Quotes Decor Items | Wall Hanging for Decoration',
        brand: BRANDS.ArtVibes,
        categories: [CATEGORIES.KITCHEN_DECOR],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B0B7JNSV8Y',
        image: '00000377.png',
    },
    {
        id: '00000378',
        title: 'Hand-Crafted Wooden Pizza & Snack Serving Plate/Tray/Dish for Kitchen/Home/Café/Restaurants (Sheesham Wood)',
        brand: BRANDS.All_About_Wood,
        categories: [CATEGORIES.KITCHEN_DECOR],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B092GTW3MQ',
        image: '00000378.png',
    },
    {
        id: '00000379',
        title: 'Kitchen Decoration Frames Home Decor',
        brand: BRANDS.Paper_Plane_Design,
        categories: [CATEGORIES.KITCHEN_DECOR],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B09NRJC7Q2',
        image: '00000379.jpg',
    },
    {
        id: '00000380',
        title: 'Decals Design StickersKart Wall Stickers Stylish Kitchen Art ',
        brand: BRANDS.Decals_Design,
        categories: [CATEGORIES.KITCHEN_DECOR],
        tags: [],
        targetLink: 'https://www.amazon.in/dp/B00NMBB65C',
        image: '00000380.jpg',
    },
]
